import 'lightcase/src/js/lightcase.js';
import 'select2/dist/js/select2.min.js';
import 'flickity/dist/flickity.pkgd.min.js';
import Flickity from 'flickity';
import FileSaver from 'file-saver';

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    gallerySlider();
    delayHomeBannerVideo();
    csvToXML();

    /* eslint-disable */
    if (typeof(NumberOfEvents) !== 'undefined') {
      let eventsNumber = parseInt(NumberOfEvents);
      for (let index = 0; index < eventsNumber; index++) {
        $(`#download-guests-${index+1}`).click(function (e) {
          e.preventDefault();
          var blob = new Blob([eval(`jsonEventInvitees${index+1}`)], {type: 'text/plain;charset=utf-8'});
          FileSaver.saveAs(blob, `${stringEntityDecode(eval(`jsonEventData${index+1}`))}.csv`);
        });
      }
    }
    /* eslint-enable */

    let eventCount = $('#rsvp-page').find('input[name=event-count]').val();
    let maxInvitees = 15;

    for (let index = 1; index <= eventCount; index++) {
      let count = $('#rsvp-page #event-'+index).find('input[name=count]').val();
      let name = $('#rsvp-page #event-'+index).find('input[name=name]').val();
      let date = $('#rsvp-page #event-'+index).find('input[name=date]').val();
      let start_time = $('#rsvp-page #event-'+index).find('input[name=start_time]').val();
      let end_date = $('#rsvp-page #event-'+index).find('input[name=end_date]').val();
      let end_time = $('#rsvp-page #event-'+index).find('input[name=end_time]').val();
      let code = $('#rsvp-page #event-'+index).find('input[name=code]').val();
      $('#rsvp-page #event-'+index).find('input[name=invitee-code').val(code);

      for (let i = 1; i < count + 1; i++) {
        let input = $('#rsvp-page #event-'+index).find('input[name='+i+']').val();
        $('#rsvp-page #event-'+index).find('input[name=invitee-'+i+']').val(input);
        $('#rsvp-page #event-'+index).find('input[name=response-'+i+']').prop('id','custom-switch-'+index+'-'+i);
        $('#rsvp-page #event-'+index+' .invitee-wrp-'+i).find('.custom-control-label').attr('for','custom-switch-'+index+'-'+i);

        $('#rsvp-page #event-'+index).find('select[name=response-dropdown-'+i+']').prop('id','response-dropdown-'+index+'-'+i);
        $('#rsvp-page #event-'+index+' .invitee-wrp-'+i).find('.response-dropdown-label-'+i).prop('id','response-dropdown-label-'+index+'-'+i);

        $('#response-dropdown-'+index+'-'+i).select2({
          dropdownParent: $('#response-dropdown-label-'+index+'-'+i),
        });
      }

      for (let j = maxInvitees; j > count; j--) {
        $('#rsvp-page #event-'+index).find('.invitee-wrp-'+j).hide();
      }

      if (count == 0) {
        // $('#rsvp-page #event-'+index).find('.contact-wrp').hide();
        // $('#rsvp-page #event-'+index).find('.confirm-btn-wrp').hide();
        // $('#rsvp-page #event-'+index+' #rsvp-'+index).prop('disabled',true);
        $('#rsvp-page #event-'+index).hide();
      }

      $('#rsvp-page #event-'+index+' #rsvp-'+index).click(function (e) {
        e.preventDefault();
        $('#rsvp-page #event-'+index+' #form-'+index).slideToggle();
      });

      $(document).on('submit','#rsvp-page #event-'+index+' #form-'+index+' form',function() {
        setTimeout(function() {
          if ($('#rsvp-page #event-'+index+' #form-'+index+' form').hasClass('sent')) {
            $('#rsvp-page #event-'+index+' #rsvp-'+index).text('RSVP SUBMITTED');
            $('#rsvp-page #event-'+index+' #rsvp-'+index).prop('disabled',true);
            $('#rsvp-page #event-'+index+' #form-'+index).toggle();
          }
        }, 3000);
      });

      let config = {
        name: name,
        description: name + ' on ' + date + ' at ' + start_time,
        startDate: date,
        endDate: end_date ? end_date : date,
        startTime: start_time,
        endTime: end_time ? end_time : start_time,
        options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams', 'Yahoo'],
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        trigger: 'click',
        iCalFileName: 'Reminder-Event',
      };
      let button = document.getElementById('add-to-calender-'+index);
      // eslint-disable-next-line no-undef
      button.addEventListener('click', () => atcb_action(config, button));

      $('.form-dropdown-'+index).select2({
        dropdownParent: $('.dropdown-'+index),
      });
    }

    $('header .mobile-menu').click(function(){
      $('body').toggleClass('mobile-menu-active');
    });

    jQuery('a[data-rel^=lightcase]').lightcase({
      slideshowAutoStart: false,
    });
  },
};

$(window).on('resize', function () {
  $('.gallery-section .gallery-wrapper').flickity(
    'resize'
  );
});

function gallerySlider() {
  let $gallerySection = $('.gallery-section .gallery-wrapper');

    if ($gallerySection.length > 0) {

      if (matchMedia('screen and (max-width: 992px)').matches ) {

        var cellAlignValue = ''

        if (matchMedia('screen and (max-width: 768px)').matches ) {
          cellAlignValue = 'center';
        } else {
          cellAlignValue = 'left';
        }

        $gallerySection.flickity({
          contain: false,
          pageDots: false,
          prevNextButtons: false,
          adaptiveHeight: false,
          wrapAround: false,
          cellAlign: cellAlignValue,
          watchCSS: true,
        });
      }
    }
}


// Delay Home Banner Video
function delayHomeBannerVideo() {
  setTimeout(function() {
    $('#home-banner-iframe').attr('src',$('#hidden-iframe-url').text());
    $('#home-banner-video').attr('src',$('#hidden-video-file').text());
  }, 3000);
}
// Delay Home Banner Video

// Convert HTML Entity to Character
function stringEntityDecode(string){
  return $('<textarea />').html(string).text();
}
// Convert HTML Entity to Character

// CSV to XML Convert Invitees
function csvToXML() {
  $('#import-xml-create-button').click(function (e) {
    e.preventDefault();
    /* eslint-disable */
    if (typeof(importCSVArray) !== 'undefined') {
      let startPageID = 2;
      let CSVData = JSON.parse(importCSVArray);
      let stringValue = createXML(CSVData, startPageID);

      var blob = new Blob([stringValue], {type: 'text/plain;charset=utf-8'});
      FileSaver.saveAs(blob, 'fileExport.xml');
    }
    /* eslint-enable */
  });
}
// CSV to XML Convert Invitees

function createXML(csvArray, startId) {
  let pageID = startId;

  let startString = `<?xml version="1.0" encoding="UTF-8" ?>
                      <!-- This is a WordPress eXtended RSS file generated by WordPress as an export of your site. -->
                      <!-- It contains information about your site's posts, pages, comments, categories, and other content. -->
                      <!-- You may use this file to transfer that content from one site to another. -->
                      <!-- This file is not intended to serve as a complete backup of your site. -->

                      <!-- To import this information into a WordPress site follow these steps: -->
                      <!-- 1. Log in to that site as an administrator. -->
                      <!-- 2. Go to Tools: Import in the WordPress admin panel. -->
                      <!-- 3. Install the "WordPress" importer from the list. -->
                      <!-- 4. Activate & Run Importer. -->
                      <!-- 5. Upload this file using the form provided on that page. -->
                      <!-- 6. You will first be asked to map the authors in this export file to users -->
                      <!--    on the site. For each author, you may choose to map to an -->
                      <!--    existing user on the site or to create a new user. -->
                      <!-- 7. WordPress will then import each of the posts, pages, comments, categories, etc. -->
                      <!--    contained in this file into your site. -->

                      <!-- generator="WordPress/6.2.2" created="2024-03-26 08:57" -->
                      <rss version="2.0"
                        xmlns:excerpt="http://wordpress.org/export/1.2/excerpt/"
                        xmlns:content="http://purl.org/rss/1.0/modules/content/"
                        xmlns:wfw="http://wellformedweb.org/CommentAPI/"
                        xmlns:dc="http://purl.org/dc/elements/1.1/"
                        xmlns:wp="http://wordpress.org/export/1.2/"
                      >

                      <channel>
                        <title>Andrew &#38; Vaneezeh</title>
                        <link>//localhost:3000</link>
                        <description>Sealed With A Kiss, Luxury Event Design</description>
                        <pubDate>Tue, 26 Mar 2024 08:57:35 +0000</pubDate>
                        <language>en-US</language>
                        <wp:wxr_version>1.2</wp:wxr_version>
                        <wp:base_site_url>//localhost:3000</wp:base_site_url>
                        <wp:base_blog_url>//localhost:3000</wp:base_blog_url>
                        <wp:author><wp:author_id>1</wp:author_id><wp:author_login><![CDATA[developers@smashydesign.com]]></wp:author_login><wp:author_email><![CDATA[developers@smashydesign.com]]></wp:author_email><wp:author_display_name><![CDATA[developers@smashydesign.com]]></wp:author_display_name><wp:author_first_name><![CDATA[]]></wp:author_first_name><wp:author_last_name><![CDATA[]]></wp:author_last_name></wp:author>
                        <generator>https://wordpress.org/?v=6.2.2</generator>

                        <image>
                          <url>//localhost:3000/wp-content/uploads/2023/12/FaviconTransparent.png</url>
                          <title>Andrew &amp; Vaneezeh</title>
                          <link>//localhost:3000</link>
                          <width>32</width>
                          <height>32</height>
                        </image> `;

  let endString = `</channel>
                    </rss>`;

  let centerString;
  let totalString = startString;

  csvArray.forEach(element => {
    if (element.length > 1) {
      centerString = `<item>
                        <title><![CDATA[${element[1]} - ${element[0]}]]></title>
                        <link>//localhost:3000/${element[0].split(' ').join('-').toLowerCase()}-${element[1].toLowerCase()}/</link>
                        <pubDate>Tue, 26 Mar 2024 08:55:44 +0000</pubDate>
                        <dc:creator><![CDATA[developers@smashydesign.com]]></dc:creator>
                        <guid isPermaLink="false">//localhost:3000/?p=${pageID}</guid>
                        <description></description>
                        <content:encoded><![CDATA[]]></content:encoded>
                        <excerpt:encoded><![CDATA[]]></excerpt:encoded>
                        <wp:post_id>${pageID}</wp:post_id>
                        <wp:post_date><![CDATA[2024-03-26 08:55:44]]></wp:post_date>
                        <wp:post_date_gmt><![CDATA[2024-03-26 08:55:44]]></wp:post_date_gmt>
                        <wp:post_modified><![CDATA[2024-03-26 08:56:26]]></wp:post_modified>
                        <wp:post_modified_gmt><![CDATA[2024-03-26 08:56:26]]></wp:post_modified_gmt>
                        <wp:comment_status><![CDATA[open]]></wp:comment_status>
                        <wp:ping_status><![CDATA[open]]></wp:ping_status>
                        <wp:post_name><![CDATA[${element[0].split(' ').join('-').toLowerCase()}-${element[1].toLowerCase()}]]></wp:post_name>
                        <wp:status><![CDATA[publish]]></wp:status>
                        <wp:post_parent>0</wp:post_parent>
                        <wp:menu_order>0</wp:menu_order>
                        <wp:post_type><![CDATA[post]]></wp:post_type>
                        <wp:post_password><![CDATA[]]></wp:post_password>
                        <wp:is_sticky>0</wp:is_sticky>
                        <category domain="category" nicename="invitee"><![CDATA[Invitee]]></category>
                        <wp:postmeta>
                          <wp:meta_key><![CDATA[_edit_last]]></wp:meta_key>
                          <wp:meta_value><![CDATA[1]]></wp:meta_value>
                        </wp:postmeta>
                        <wp:postmeta>
                          <wp:meta_key><![CDATA[name]]></wp:meta_key>
                          <wp:meta_value><![CDATA[${element[0]}]]></wp:meta_value>
                        </wp:postmeta>
                        <wp:postmeta>
                          <wp:meta_key><![CDATA[_name]]></wp:meta_key>
                          <wp:meta_value><![CDATA[field_6358b507268b8]]></wp:meta_value>
                        </wp:postmeta>
                        <wp:postmeta>
                          <wp:meta_key><![CDATA[code]]></wp:meta_key>
                          <wp:meta_value><![CDATA[${element[1]}]]></wp:meta_value>
                        </wp:postmeta>
                        <wp:postmeta>
                          <wp:meta_key><![CDATA[_code]]></wp:meta_key>
                          <wp:meta_value><![CDATA[field_6358b52e268b9]]></wp:meta_value>
                        </wp:postmeta>
                        <wp:postmeta>
                          <wp:meta_key><![CDATA[bride_groom]]></wp:meta_key>
                          <wp:meta_value><![CDATA[${element[2]}]]></wp:meta_value>
                        </wp:postmeta>
                        <wp:postmeta>
                          <wp:meta_key><![CDATA[_bride_groom]]></wp:meta_key>
                          <wp:meta_value><![CDATA[field_6380a58dabe81]]></wp:meta_value>
                        </wp:postmeta>
                        <wp:postmeta>
                          <wp:meta_key><![CDATA[inline_featured_image]]></wp:meta_key>
                          <wp:meta_value><![CDATA[0]]></wp:meta_value>
                        </wp:postmeta>
                      </item>`;

      totalString += centerString;
      pageID += 2;
    }
  });

  totalString += endString;

  return totalString;
}